import fetchJSON from "./FetchJSON";

const US_REGIONS: any = {
  us_northeast: [
    "Pennsylvania",
    "New Jersey",
    "Connecticut",
    "New York",
    "Rhode Island",
    "Massachusetts",
    "New Hampshire",
    "Vermont",
    "Maine",
  ],
  us_midwest: [
    "North Dakota",
    "South Dakota",
    "Nebraska",
    "Kansas",
    "Minnesota",
    "Iowa",
    "Missouri",
    "Wisconsin",
    "Illinois",
    "Indiana",
    "Michigan",
    "Ohio",
  ],
  us_south: [
    "Texas",
    "Oklahoma",
    "Arkansas",
    "Louisiana",
    "Kentucky",
    "Indiana",
    "Mississippi",
    "Alabama",
    "Georgia",
    "South Carolina",
    "North Dakota",
    "Virginia",
    "District of Columbia",
    "Maryland",
    "Delaware",
    "Florida",
    "West Virginia",
  ],
  us_west: [
    "California",
    "Oregon",
    "Washington",
    "Nevada",
    "Arizona",
    "Utah",
    "New Mexico",
    "Colorado",
    "Wyoming",
    "Idaho",
    "Montana",
  ],
};

let fetchLocation = (address: any, setAlert: any, setAlertContent: any) => {
  if (!address.endsWith(', Canada') && !address.endsWith(', USA')) {
    address += ", USA"
  }
  let GoogleMapGeocodingAPI =
    `https://maps.googleapis.com/maps/api/geocode/json?` +
    new URLSearchParams({
      address: address,
      key: process.env.REACT_APP_GOOGLE_MAP_APIKEY
        ? process.env.REACT_APP_GOOGLE_MAP_APIKEY
        : "",
    });
  return fetchJSON(GoogleMapGeocodingAPI, setAlert, setAlertContent)
    .then((respJSON: any) => {
      let addr_components = respJSON["results"][0]["address_components"];
      let region = "canada";
      for (let component of addr_components) {
        if (
          "types" in component &&
          component["types"].includes("administrative_area_level_1")
        ) {
          respJSON["results"][0]["state_province"] = component["long_name"];
          for (let us_region in US_REGIONS) {
            if (US_REGIONS[us_region].includes(component["long_name"])) {
              region = us_region;
              break;
            }
          }
          break;
        }
      }
      respJSON["results"][0]["region"] = region;
      return respJSON["results"][0];
    })
    .catch((error) => {
      setAlert(true);
      setAlertContent({
        original: error.message,
        show: "Place not found!",
      });
    });
};

export default fetchLocation;
