import { useNavigate } from "react-router-dom";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import { Toolbar } from "../components/Toolbar/Toolbar";
import { Footer } from "../components/Footer/Footer";
import { SearchBox } from "../components/SearchBox/SearchBox";
import LandingImage from "./../assets/images/background.webp";
import CommuteImage from "./../assets/images/commute.webp";
import HistogramImage from "./../assets/images/histogram.webp";
import TrafficImage from "./../assets/images/traffic.webp";
import IsochroneImage from "./../assets/images/isochrone.webp";
import "./Home.css";

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const address = useRef<string>("");
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState<any>({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let addressAutocompleteCallback = (props: any, newValue: any) => {
    address.current = newValue["description"];
  };

  const onButtonClick = () => {
    navigate(`/places/${address.current}`);
  };

  useEffect(() => {
    document.body.classList.add("HomePage");
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <script src="https://www.homeeazy.net/assembly.js"></script>
          <meta charSet="utf-8" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="HomeEazy - Apartment hunting for commuters!"
          />
          <meta
            property="og:image"
            content="https://www.homeeazy.net/bayarea.webp"
          />
          <meta property="og:url" content="https://www.homeeazy.net/" />
          <meta property="og:site_name" content="HomeEazy" />
          <meta
            property="og:description"
            content="HomeEazy can filter the apartments by commute time to
                    your school / working place, check the price distribution,
                    filter and find the place that belongs to you, even in the
                    most unaffordable cities in America."
          />
          <title>HomeEazy - Apartment hunting for commuters!</title>
        </Helmet>
      </HelmetProvider>
      <Toolbar />
      <div className="HomePageContainer">
        {alert ? (
          <Alert severity="error">
            {process.env.REACT_APP_ORIGINAL_ERROR
              ? alertContent["original"]
              : alertContent["show"]}
          </Alert>
        ) : (
          <></>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          maxWidth="lg"
        >
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <Typography variant="h5">
                  Apartment hunting made easy.
                </Typography>
                <img
                  alt="HomeEazy"
                  style={{
                    height: "auto",
                    width: "581px",
                    transform: isSmallScreen ? "scale(0.5)" : "none",
                  }}
                  src={LandingImage}
                ></img>
                <Typography variant="subtitle1" align="left">
                  HomeEazy is the best app in your apartment hunting -- in one
                  minute, you can filter the apartments by commute time to your
                  school / working place, check the price distribution, filter
                  and find the place that belongs to you, even in the most
                  unaffordable cities in America.
                </Typography>
              </div>
            </Grid>
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <SearchBox
                  address={address}
                  setAlert={setAlert}
                  setAlertContent={setAlertContent}
                  callback={addressAutocompleteCallback}
                  onClick={onButtonClick}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <Divider variant="middle"></Divider>
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <Typography variant="h5">Search by Commute</Typography>
                <Typography variant="subtitle1" align="left">
                  HomeEazy can help you filter apartments by reachability map
                  (isochrone) from your work / school, so you can find the
                  apartments best suited for your daily commute in a few
                  seconds.
                </Typography>
              </div>
            </Grid>
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <img
                  alt="Commute"
                  style={{
                    height: "auto",
                    width: "761px",
                    transform: isSmallScreen ? "scale(0.5)" : "none",
                  }}
                  src={CommuteImage}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <Divider variant="middle"></Divider>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <img
                  className="IsochroneImage"
                  alt="Isochrone"
                  style={{
                    transform: isSmallScreen ? "scale(0.6)" : "none",
                    height: "auto",
                  }}
                  src={IsochroneImage}
                ></img>
              </div>
            </Grid>

            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <Typography variant="h5">Sort by Commute Time</Typography>
                <Typography variant="subtitle1" align="left">
                  With advanced algorithm powered by AI, we can sort the
                  apartments closest to your work / school in one second.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <Divider variant="middle"></Divider>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <Typography variant="h5">Transit and Traffic</Typography>
                <Typography variant="subtitle1" align="left">
                  Now supporting 8am transit in US and driving with typical
                  traffic of 8am in California.
                </Typography>
              </div>
            </Grid>
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <img
                  alt="Traffic"
                  style={{
                    height: "auto",
                    width: "761px",
                    transform: isSmallScreen ? "scale(0.5)" : "none",
                  }}
                  src={TrafficImage}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <Divider variant="middle"></Divider>
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            lg={9}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <img
                  className="HistogramImage"
                  alt="Histogram"
                  style={{ transform: isSmallScreen ? "scale(0.6)" : "none" }}
                  src={HistogramImage}
                ></img>
              </div>
            </Grid>
            <Grid item sm={12} lg={6}>
              <div className="HomeContainer">
                <Typography variant="h5">Price Histogram</Typography>
                <Typography variant="subtitle1" align="left">
                  Price distribution of apartments within the isochrone -- so
                  you can make a budget in 1 minute.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
