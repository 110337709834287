import Pbf from "pbf";
import geobuf from "geobuf";

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import BarChartIcon from "@mui/icons-material/BarChart";
import MenuIcon from "@mui/icons-material/Menu";

import Mapbox from "./components/Map";
import Legend from "./components/Legend";
import AddressAutocomplete from "./components/AddressAutocomplete";
import CommuteDialog from "./components/dialogs/CommuteDialog";
import fetchJSON from "./commons/fetch/FetchJSON";
import fetchLocation from "./commons/fetch/FetchLocation";
import B64ToArrayBuffer from "./commons/string/B64ToArrayBuffer";
import renderGeoJSON, { removeGeoJSON } from "./commons/map/RenderGeoJSON";
import markerCluster from "./commons/map/MarkerCluster";
import { area } from "./commons/ref/Area";
import useWindowDimensions from "./commons/window/useWindowDimensions";
import FilterDialog from "./components/dialogs/FilterDialog";
import HistogramDialog from "./components/dialogs/HistogramDialog";
import SortLabel from "./components/SortLabel";
import Logo from "./components/Logo";
import ApartmentsList from "./components/ApartmentsList";
import { truncateString } from "./commons/string/truncateString";
import { responsiveCols } from "./commons/window/responsiveCols";

import "./Places.css";

type Anchor = "top" | "left" | "bottom" | "right";

export default function Places() {
  const [map, setMap] = useState<any>(null);
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const { place } = useParams();
  const [commuteDialogOpen, setCommuteDialogOpen] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [histogramDialogOpen, setHistogramDialogOpen] = useState(false);
  const [sources, setSources] = useState([]);
  const [legends, setLegends] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [layers, setLayers] = useState([]);
  const [marker, setMarker] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState<any>({});
  const [sortCol, setSortCol] = useState("cl_posted");
  const [priceSort, setPriceSort] = useState("asc");
  const [postedSort, setPostedSort] = useState("desc");
  const [distSort, setDistSort] = useState("asc");
  const [sqftSort, setSqftSort] = useState("desc");
  const [apaJSONs, setApaJSONs] = useState<Array<any>>([]);
  const [dist, setDist] = useState({});

  const address = useRef<string>(place ? place : "");
  const commuteAddress = useRef<string>("");
  const ourArea = useRef("sfbay");
  const type = useRef("apa");
  const bedroom = useRef<string[]>([]);
  const bathroom = useRef<string[]>([]);
  const imagesOnly = useRef("");
  const amenities = useRef("");
  const region = useRef("us_west");
  const method = useRef("transit");
  const limit = useRef(
    parseInt(
      method.current === "transit"
        ? process.env.REACT_APP_LIMIT_TRANSIT_MIN!
        : process.env.REACT_APP_LIMIT_DRIVE_MIN!
    )
  );
  const fromTo = useRef("to");
  const price = useRef<number[]>([
    0,
    parseInt(process.env.REACT_APP_HISTOGRAM_MAX!),
  ]);
  const sqft = useRef<number[]>([0, 2000]);
  const location = useRef({ lat: 0.0, lng: 0.0 });
  const origin = useRef({
    lat: process.env.REACT_APP_ORIGIN_LATITUDE,
    lng: process.env.REACT_APP_ORIGIN_LONGITUDE,
  });
  const clusterLoadedRef = useRef(false);
  const isocontourRef = useRef(false);
  const listingsRef = useRef<any>([]);
  const pageRef = useRef(0);
  const oldFuncRef = useRef(null);

  const handleCommuteDialogOpen = () => {
    setCommuteDialogOpen(true);
  };

  const handleCommuteDialogClose = (value: string) => {
    setCommuteDialogOpen(false);
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = (value: string) => {
    setFilterDialogOpen(false);
  };

  const handleHistogramDialogOpen = () => {
    setHistogramDialogOpen(true);
  };

  const handleHistogramDialogClose = (value: string) => {
    setHistogramDialogOpen(false);
  };

  const sortListing = () => {
    let listings = listingsRef.current;
    listings.sort(
      (a: any, b: any) => a.properties[sortCol] - b.properties[sortCol]
    );
    if (
      (sortCol === "price" && priceSort === "desc") ||
      (sortCol === "cl_posted" && postedSort === "desc") ||
      (sortCol === "dist" && distSort === "desc") ||
      (sortCol === "sqft" && sqftSort === "desc")
    ) {
      listings.reverse();
    }
    listingsRef.current = listings;
  };

  const loadListing = (clear: boolean) => {
    let newListings = listingsRef.current.slice(
      pageRef.current * 30,
      pageRef.current * 30 + 30
    );
    if (newListings.length === 0) {
      return;
    }
    fetchJSON(
      `${process.env.REACT_APP_BRUSSELS_HOST}/crawler/?` +
        new URLSearchParams({
          cl_ids: newListings
            .map((listing: any) => listing.properties.cl_id.toString())
            .join(","),
          cl_dept: "apa",
        }),
      setAlert,
      setAlertContent
    )
      .then((apaJSONList: any) => {
        if (clear) {
          setApaJSONs(apaJSONList);
        } else {
          setApaJSONs(apaJSONs.concat(apaJSONList));
        }
      })
      .catch((error) => {
        setAlert(true);
        setAlertContent({
          original: error.message,
          show: "Network Error!",
        });
      });
  };

  const loadListingInit = () => {
    if (listingsRef.current !== undefined) {
      sortListing();
      setLoaded(false);
      pageRef.current = 0;
      loadListing(true);
    }
  };

  useEffect(() => {
    loadListingInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState, sortCol, priceSort, postedSort, distSort, sqftSort]);

  const loadMore = () => {
    pageRef.current += 1;
    loadListing(false);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState({ ...drawerState, [anchor]: open });
    };

  const query = () => {
    fetchJSON(
      `${process.env.REACT_APP_BRUSSELS_HOST}/isocontour/?` +
        new URLSearchParams({
          region: region.current,
          tlat: area[ourArea.current]._ne.lat.toString(),
          blat: area[ourArea.current]._sw.lat.toString(),
          llon: area[ourArea.current]._sw.lng.toString(),
          rlon: area[ourArea.current]._ne.lng.toString(),
          isocontour: isocontourRef.current.toString(),
          src_lat: location.current.lat.toString(),
          src_lon: location.current.lng.toString(),
          min: (
            parseInt(
              method.current === "transit"
                ? process.env.REACT_APP_LIMIT_TRANSIT_MIN!
                : process.env.REACT_APP_LIMIT_DRIVE_MIN!
            ) * 60
          ).toString(),
          max: (limit.current * 60).toString(),
          step: (
            parseInt(
              method.current === "transit"
                ? process.env.REACT_APP_LIMIT_TRANSIT_STEP!
                : process.env.REACT_APP_LIMIT_DRIVE_STEP!
            ) * 60
          ).toString(),
          type: type.current,
          images: imagesOnly.current,
          bedroom: bedroom.current.join(","),
          bathroom: bathroom.current.join(","),
          amenities: amenities.current,
          price: price.current.map(String).join(","),
          sqft: sqft.current.map(String).join(","),
          to: fromTo.current,
          method: method.current,
        }),
      setAlert,
      setAlertContent
    )
      .then((geoJSON: any) => {
        removeGeoJSON(
          map,
          sources,
          layers,
          marker,
          setSources,
          setLayers,
          setMarker
        );
        if (isocontourRef.current === true) {
          renderGeoJSON(
            geoJSON["isocontour"],
            geoJSON["source"],
            map,
            setLegends,
            setSources,
            setLayers,
            setMarker
          );
        }
        return geoJSON;
      })
      .then((geoJSON: any) => {
        let listings: any = geobuf.decode(
          new Pbf(B64ToArrayBuffer(geoJSON["listings"]))
        );
        listingsRef.current = listings["features"];
        let newDist =
          isocontourRef.current && listingsRef.current
            ? Object.assign(
                {},
                ...listingsRef.current.map((x: any) => ({
                  [x.properties.cl_id]: x.properties.dist.toString(),
                }))
              )
            : {};
        setDist(newDist);
        loadListingInit();
        markerCluster(
          listings,
          map,
          setAlert,
          setAlertContent,
          clusterLoadedRef,
          newDist,
          method,
          oldFuncRef
        );
      });
  };

  const onQueryIsocontour = (
    newLocation: any,
    newRegion: string,
    newLimit: number,
    newFromTo: string,
    newMethod: string,
    description: string
  ) => {
    isocontourRef.current = true;
    location.current = newLocation;
    map.flyTo({
      center: location.current,
      zoom: 12,
    });
    window.history.replaceState(
      null,
      "",
      `/places/${encodeURIComponent(description)}`
    );
    region.current = newRegion;
    limit.current = newLimit;
    method.current = newMethod;
    fromTo.current = newFromTo;
    query();
    address.current = description;
  };

  const onFilterChange = (
    newType: string,
    newImagesOnly: string,
    newBedroom: string[],
    newBathroom: string[],
    newAmenities: string,
    newPrice: number[],
    newSqft: number[]
  ) => {
    return new Promise((resolve, reject) => {
      type.current = newType;
      imagesOnly.current = newImagesOnly;
      bedroom.current = newBedroom;
      bathroom.current = newBathroom;
      amenities.current = newAmenities;
      price.current = newPrice;
      sqft.current = newSqft;
      query();
    });
  };

  const handleChangeSorting = (event: any, newSortCol: string) => {
    if (newSortCol !== null) {
      setSortCol(newSortCol);
    }
  };

  const handleChangePriceSorting = (event: any) => {
    if (sortCol === "price") {
      setPriceSort(priceSort === "desc" ? "asc" : "desc");
    }
  };

  const handleChangePostedSorting = (event: any) => {
    if (sortCol === "cl_posted") {
      setPostedSort(postedSort === "desc" ? "asc" : "desc");
    }
  };

  const handleChangeDistSorting = (event: any) => {
    if (sortCol === "dist") {
      setDistSort(distSort === "desc" ? "asc" : "desc");
    }
  };

  const handleChangeSqftSorting = (event: any) => {
    if (sortCol === "sqft") {
      setSqftSort(sqftSort === "desc" ? "asc" : "desc");
    }
  };

  const drawer = (drawerType: string, windowWidth: number) => {
    return (
      <div className="drawerBox">
        <Toolbar />
        {windowWidth < parseInt(process.env.REACT_APP_PERMENANT_WIDTH!) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "start",
              ml: 1,
              mt: 1.5,
            }}
          >
            <Logo />
            <Typography
              style={{ display: "block" }}
              sx={{
                ml: 0.5,
                mt: 0.5,
                mb: 0.5,
              }}
            >
              Apartments near{" "}
              {address.current === ""
                ? area[ourArea.current].name
                : truncateString(address.current, 20)}
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            xs={12}
            justifyContent="space-between"
            item={true}
            sx={{
              mt: 1,
            }}
          >
            <Box style={{ display: "inline-block" }}>
              <Typography
                style={{ display: "inline-block" }}
                sx={{
                  ml: 1.5,
                  mt: 1,
                }}
              >
                Apartments near{" "}
                {address.current === ""
                  ? area[ourArea.current].name
                  : truncateString(address.current, 40)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: 1,
                mr: 1.5,
              }}
              style={{ display: "inline-block" }}
            >
              <Logo />
            </Box>
          </Grid>
        )}
        <Divider />
        <ToggleButtonGroup
          value={sortCol}
          exclusive
          className="mt6 mb6 ml6"
          onChange={handleChangeSorting}
          aria-label="Platform"
        >
          <ToggleButton value="price" onClick={handleChangePriceSorting}>
            <SortLabel label="price" sort={priceSort}></SortLabel>
          </ToggleButton>
          <ToggleButton value="cl_posted" onClick={handleChangePostedSorting}>
            <SortLabel label="posted" sort={postedSort}></SortLabel>
          </ToggleButton>
          <ToggleButton
            value="dist"
            onClick={handleChangeDistSorting}
            disabled={!isocontourRef.current}
          >
            <SortLabel label="commute" sort={distSort}></SortLabel>
          </ToggleButton>
          {responsiveCols(drawerType, windowWidth) === 2 && (
            <ToggleButton value="sqft" onClick={handleChangeSqftSorting}>
              <SortLabel label="sqft" sort={sqftSort}></SortLabel>
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        <Divider />
        <ApartmentsList
          loaded={loaded}
          setLoaded={setLoaded}
          loadMore={loadMore}
          apaJSONList={apaJSONs}
          dist={dist}
          sortCol={sortCol}
          priceSort={priceSort}
          postedSort={postedSort}
          distSort={distSort}
          sqftSort={sqftSort}
          method={method}
          drawerType={drawerType}
        ></ApartmentsList>
      </div>
    );
  };

  let mapFlyTo = (coord: any, description: string) => {
    if (map) {
      isocontourRef.current = false;
      setLegends(null);
      window.history.replaceState(null, "", "/places/" + description);
      map.flyTo({
        center: coord,
        zoom: 12,
      });
      query();
    }
  };

  let addressAutocompleteCallback = (props: any, newValue: any) => {
    fetchLocation(
      newValue["description"],
      props.setAlert,
      props.setAlertContent
    )
      .then((respJSON: any) => {
        address.current = newValue["description"];
        mapFlyTo(
          respJSON["geometry"]["location"],
          encodeURIComponent(newValue["description"])
        );
      })
      .catch((error: any) => {
        props.setAlert(true);
        props.setAlertContent({
          original: error.message,
          show: "Place not found!",
        });
      });
  };

  return (
    <div className="Places">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={
              place ? `HomeEazy - Apartments near ${place}!` : "HomeEazy"
            }
          />
          <meta
            property="og:image"
            content="https://www.homeeazy.net/bayarea.webp"
          />
          <meta
            property="og:url"
            content={
              place
                ? `https://www.homeeazy.net/places/${encodeURIComponent(place)}`
                : "https://www.homeeazy.net/places/"
            }
          />
          <meta property="og:site_name" content="HomeEazy" />
          <meta
            property="og:description"
            content="HomeEazy can filter the apartments by commute time to
                    your school / working place, check the price distribution,
                    filter and find the place that belongs to you, even in the
                    most unaffordable cities in America."
          />
          <title>
            {place
              ? `HomeEazy - Apartment hunting for commuters near ${place}!`
              : "HomeEazy - Apartment hunting for commuters!"}
          </title>
        </Helmet>
      </HelmetProvider>
      <AppBar
        position="fixed"
        color="secondary"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer("left", true)}
            sx={{
              mr: 1,
              display: {
                md: "none",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <AddressAutocomplete
            address={address}
            setAlert={setAlert}
            setAlertContent={setAlertContent}
            callback={addressAutocompleteCallback}
          />
          <div className="div-filter">
            <Button
              className="btn-filter"
              variant="contained"
              onClick={handleFilterDialogOpen}
            >
              Filter
            </Button>
          </div>
          <div className="div-commute">
            <Button
              className="btn-commute"
              variant="contained"
              onClick={handleCommuteDialogOpen}
            >
              Commute
            </Button>
          </div>
        </Toolbar>
        {alert ? (
          <Alert severity="error">
            {process.env.REACT_APP_ORIGINAL_ERROR === "true"
              ? alertContent["original"]
              : alertContent["show"]}
          </Alert>
        ) : (
          <></>
        )}
      </AppBar>
      <Stack className="stack-btns" spacing={2}>
        <Tooltip title="Histogram">
          <Fab
            className="fab-histogram"
            color="primary"
            onClick={handleHistogramDialogOpen}
            aria-label="add"
          >
            <BarChartIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="Commute">
          <Fab
            className="fab-commute"
            color="primary"
            onClick={handleCommuteDialogOpen}
            aria-label="add"
          >
            <DepartureBoardIcon />
          </Fab>
        </Tooltip>
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerState["left"]}
          onClose={toggleDrawer("left", false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box" },
          }}
        >
          {drawer("temporary", useWindowDimensions().width)}
        </Drawer>
        <Drawer
          variant={"permanent"}
          sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { boxSizing: "border-box" },
            display: { xs: "none", sm: "none", md: "block" },
          }}
          open
        >
          {drawer("permanent", useWindowDimensions().width)}
        </Drawer>
      </Stack>
      <CommuteDialog
        open={commuteDialogOpen}
        onClick={onQueryIsocontour}
        onClose={handleCommuteDialogClose}
        setAlert={setAlert}
        setAlertContent={setAlertContent}
        address={commuteAddress}
      />
      <FilterDialog
        open={filterDialogOpen}
        onClick={onFilterChange}
        onClose={handleFilterDialogClose}
        setAlert={setAlert}
        setAlertContent={setAlertContent}
      />
      <HistogramDialog
        open={histogramDialogOpen}
        onClose={handleHistogramDialogClose}
        listings={listingsRef.current}
      />
      <Legend legends={legends} />
      <Mapbox
        map={map}
        setMap={setMap}
        origin={origin.current}
        place={place}
        zoom={process.env.REACT_APP_ORIGIN_ZOOM}
        ourArea={ourArea}
        query={query}
        isocontourRef={isocontourRef}
        setAlert={setAlert}
        setAlertContent={setAlertContent}
        windowWidth={useWindowDimensions().width}
      />
    </div>
  );
}
